import Helmet from 'react-helmet';
import './App.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import Router from './Router/Router';

function App() {
  return (
    <div className="App">
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <title>Know Ukraine - Дані про війну в Україні</title>
        <meta name="description" content="Про війну в цифрах та фактах. Слава Україні!" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link preload href="https://fonts.googleapis.com/css2?family=Arimo:wght@600&display=swap" rel="stylesheet" />
        <link rel="apple-touch-icon" sizes="180x180" href="/pub/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/pub/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/pub/favicon-16x16.png" />
        <link rel="manifest" href="/pub/site.webmanifest" />
        <link rel="mask-icon" href="/pub/safari-pinned-tab.svg" color="#5bbad5" />
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/uikit@3.13.3/dist/css/uikit.min.css" />
        <link rel="stylesheet" href="/icons.css" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <Router />
    </div>
  );
}

export default App;
